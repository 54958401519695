<template>
  <div>
    <v-layout
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              What is <span class="header-etsy">&epsilon;</span>dliy?
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top:5px" />
    <v-layout
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          At Edliy, we are pioneering visual interactive technology to teach science and math concepts through visual storytelling.
          MagicGraphs are visually interactive apps designed to empower students to learn from virtual experiments, test different hypotheses, and gain a 360<sup>o</sup> view of the concepts in Science, Engineering, Technology and Mathematics (STEM).
          <br>
          <br>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      justify-center
      align-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div class="font-weight-medium"
             :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Learn interactively with Edliy</span>
        </div>
        <div style="margin-top:5px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp, 'subtitle-1 px-2': $vuetify.breakpoint.mdOnly,'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Edliy offers a hands-on approach to learning science and math. Not only hands-on learning is a lot more fun, it is also proven to improve engagement and retention.
          <br>
          <a class="btnLeft mt-2 mb-4" href="/physics/2dmotion/#mgk"> Learn more!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="LightSalmon"
          pagination-active-color="Red"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-1.webm" type="video/webm">
                <source src="assets/webm-1.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox1a" class="edliy-box-abt" style="jsxbox" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <!---------------Slide 2 ------- -->
    <v-layout
      justify-center
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-1': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1': $vuetify.breakpoint.mdAndUp,'headline': $vuetify.breakpoint.mdOnly, 'title': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald">Practice to Master Concepts</span>
        </div>
        <div style="margin-top: 5px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline': $vuetify.breakpoint.lgAndUp, 'subtitle-1': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          Practice makes us perfect. MagicGraphs let you get as much practice as you need to master a concept.
          Plus you get real-time feedback &mdash; creating opportunities for you to learn and improve.
          <br>
          <a class="btnLeft mt-2 mb-4" href="/chemistry/bce/#mgk">Try it Yourself!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="LightSalmon"
          pagination-active-color="Red"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-2.webm" type="video/webm">
                <source src="assets/webm-2.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox2a" class="edliy-box-abt" style="jsxbox" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald">
            Learn at Your Own Pace
          </span>
        </div>
        <div style="margin-top: 5px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Every student is different and learns at a different pace. That's why
          every modules in Edliy is designed to be self-paced &mdash; now students can learn at a pace that works best for them.
          <br>
          <a class="btnLeft mt-2 mb-4" href="/mathematics/prob/#mgk">Try this App!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="LightSalmon"
          pagination-active-color="Red"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-3.webm" type="video/webm">
                <source src="assets/webm-3.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox6a" class="edliy-box-abt" style="jsxbox" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
      reverse="$vuetify.breakpoint.mdAndUp"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1': $vuetify.breakpoint.mdAndUp,'headline': $vuetify.breakpoint.mdOnly, 'title': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Learn the Way You Want </span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline': $vuetify.breakpoint.lgAndUp, 'subtitle-1': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          Edliy is designed for all types of learners and learning environments &mdash;
          whether you want to attend the class in person or learn online. Prefer a hybrid learning approach? Edliy works for that too.
          <br>
          <a class="btnLeft mt-2 mb-4" href="/chemistry/bce/#mgk">Try it Yourself!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="LightSalmon"
          pagination-active-color="Red"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-4.webm" type="video/webm">
                <source src="assets/webm-4.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox5a" class="edliy-box-abt" style="jsxbox" />
            </v-layout>
          </slide>
        </carousel>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
    name: 'EdliyContent',
    data () {
      return {
        style: {layout: "margin: 20px"},
            }
      },
    mounted () {
      Boxes.box1a();
      Boxes.box2a();
      Boxes.box6a();
      Boxes.box5a();
    },
    methods : {
      reloadPage(){
      window.location.reload()
    }
    }
}
</script>
